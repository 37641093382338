@import '../config';
@import 'node_modules/bootstrap/scss/buttons';

.btn-primary,
.btn.btn-primary {
  color: color('white');
  padding: 11px 18px;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    color: gray('white');
    text-decoration: none;
  }
}
.btn-outline-gray,
.btn.btn-outline-gray {
  color: gray('gray-750');
  background-color: gray('white');
  text-decoration: none;
  font-weight: 500; //font-weight: 400;;
  padding: 11px 18px;

  &:hover,
  &:focus,
  &:active {
    background-color: gray('gray-100');
  }
}

.btn-arrow {
  &::after {
    content: '';
    color: gray('white');
    display: inline-block;
    margin-left: 12px;
    height: 1em;
    width: 10px;
    background-image: url(../../assets/chevron_right_white.svg);
    background-size: contain;
    background-repeat: no-repeat;
    vertical-align: middle;
    margin-top: -2px;
  }
  &.btn-outline-gray {
    &::after {
      background-image: url(../../assets/chevron_right_dark_gray.svg);
    }
  }
}

.btn-primary--download {
  &::after {
    content: '';
    color: gray('white');
    display: inline-block;
    margin-left: 12px;
    height: 18px;
    width: 14px;
    background-image: url(../../assets/download_white.svg);
    background-size: contain;
    background-repeat: no-repeat;
    vertical-align: middle;
    margin-top: -2px;
  }
}

.btn-arrow-back {
  &::before {
    content: '';
    color: gray('white');
    display: inline-block;
    margin-right: 12px;
    height: 1em;
    width: 10px;
    background-image: url(../../assets/chevron_right_white.svg);
    background-size: contain;
    background-repeat: no-repeat;
    vertical-align: middle;
    margin-top: -2px;
    transform: rotate(180deg);
  }
  &.btn-outline-gray {
    &::before {
      background-image: url(../../assets/chevron_right_dark_gray.svg);
    }
  }
}

.btn-add {
  &::before {
    content: '';
    color: gray('white');
    display: inline-block;
    margin-right: 7px;
    height: 1em;
    width: 1em;
    background-image: url(../../assets/add_white.svg);
    background-size: contain;
    background-repeat: no-repeat;
    vertical-align: middle;
    margin-top: -2px;
  }
  &.btn-outline-gray {
    &::before {
      background-image: url(../../assets/add_gray.svg);
    }
  }
}

.btn-loading {
  &::after {
    content: '';
    color: gray('white');
    display: inline-block;
    margin-left: 12px;
    height: 30px;
    width: 30px;
    background-image: url(../../assets/loading_white.svg);
    background-size: contain;
    background-repeat: no-repeat;
    vertical-align: middle;
    margin-top: -10px;
    margin-bottom: -8px;
  }
}

.btn.btn-download {
  background-color: gray('white');
  display: inline-block;
  height: 36px;
  width: 36px;
  border: 2px solid gray('gray-200');
  background-image: url(../../assets/download_dark_gray.svg);
  background-position: center center;
  background-repeat: no-repeat;
  &:hover {
    background-color: gray('gray-100');
  }
}

.btn.btn-edit {
  display: inline-block;
  height: 36px;
  width: 36px;
  border: 2px solid gray('gray-200');
  background-color: gray('white');
  background-image: url(../../assets/crayon_gray.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 60%;
  padding: 0;
  overflow: hidden;
  text-indent: 99px;
  vertical-align: text-bottom;
  &-sm {
    height: 26px;
    width: 26px;
  }
  &:hover {
    background-color: gray('gray-100');
  }
}


.btn.btn-copy {
  display: inline-block;
  height: 26px;
  width: 26px;
  border: 2px solid gray('gray-200');
  background-color: gray('white');
  background-image: url(../../assets/copy_gray.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 60%;
  padding: 0;
  overflow: hidden;
  text-indent: 99px;
  vertical-align: text-bottom;
  &:hover {
    background-color: gray('gray-100');
  }
}

.btn.btn-delete {
  display: inline-block;
  height: 36px;
  width: 36px;
  border: 2px solid gray('gray-200');
  background-color: gray('white');
  background-image: url(../../assets/trashcan_gray.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 60%;
  padding: 0;
  overflow: hidden;
  text-indent: 99px;
  vertical-align: text-bottom;
  &-sm {
    height: 26px;
    width: 26px;
  }
  &:hover {
    background-color: gray('gray-100');
  }
}

.btn {
  // Allow line return on large button
  white-space: normal;
  &.btn-sm {
    font-size: 11px;
    padding: 3px 10px;
  }
}

@media screen and (min-width: breakpoint-min('md')) {
  .btn {
    &.btn-sm {
      font-size: 14px;
      padding: 5px 16px;
    }
  }
}
