$grid-columns: 12;
$grid-gutter-width: 30px;

$grid-breakpoints: (
  xxs: 0,
  xs: 568px,
  sm: 768px,
  md: 992px,
  lg: 1200px,
  xl: 1440px
);

$container-max-widths: (
  xs: 472px,
  sm: 540px,
  md: 896px,
  lg: 1104px,
  xl: 1296px
);
$colors: (
  'jedi': #42b3f4,
  'sith': #ff0000,
  'green': #00ff00
);

$theme-colors: (
  'primary': #82be19,
  'secondary': #007d3c,
  'success': #00a0f1,
  'warning': #ffa800,
  'danger': #df1f00,
  'orange': #ff4949,
  'light-green': #d4eb8e,
  'faded-green': #e9f4c8,
  'gray': #d3d5d8,
  'white': #ffffff
);

$form-feedback-valid-color: #bdec6b;
$form-feedback-icon-valid: none;
$enable-validation-icons: false;

$grays: (
  'white': #ffffff,
  'gray-100': #f7f8f8,
  'gray-125': #e8e8e8,
  'gray-150': #d3d5d8,
  'gray-200': #c9c9c9,
  'gray-400': #979a9f,
  'gray-600': #585f66,
  'gray-700': #40454a,
  'gray-750': #1f2d3d,
  'black': #000000
);
$font-family-base: 'Rubik', Roboto, 'Helvetica Neue', Arial, sans-serif;
$font-weight-normal: 300;
$body-color: #40454a;
$link-color: #007d3c;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: underline;
$link-decoration: underline;
$input-btn-border-width: 2px;
$input-border-radius: 6px;
$btn-border-radius-sm: 18px;
$btn-border-radius: 24px;
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/variables';
